import { ActionIcon, Card, Center, Group, Paper, Table, Tooltip } from '@mantine/core';
import { useElementSize } from '@mantine/hooks';
import { IconReload } from '@tabler/icons-react';
import axios from 'axios';
import { useEffect, useState } from 'react';

type Log = {
  timestampFrom: number;
  timestampTo: number;
  message: string;
};

interface DataItem {
  url: string;
  ens: string;
  tag: string;
  status: boolean;
  errors: Log[];
}

export function RelayerStatus() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<DataItem[]>([]);
  const { ref, width } = useElementSize();

  function updateStatus() {
    setLoading(true);
    axios
      .get<DataItem[]>('/logs')
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  }

  const paper = (color: 'green' | 'red', tag: string) => (
    <Tooltip label={tag}>
      <Paper
        radius={1}
        style={{
          width: '6px',
          height: '30px',
          backgroundColor: color,
        }}
      />
    </Tooltip>
  );

  const makePapers = (paperCount: number, errorLogs: Log[]) => {
    const papers: Array<{ color: 'green' | 'red'; tag: string }> = [];
    const current = new Date();
    current.setMilliseconds(0);
    current.setSeconds(0);
    const currentTs = current.getTime();

    let errorLogIndex = 0;
    for (let i = 0; i < paperCount; i += 1) {
      const thisTs = currentTs - i * 60 * 1000;
      let currentErrorLog = errorLogs[errorLogIndex];

      while (currentErrorLog && thisTs < currentErrorLog.timestampFrom) {
        errorLogIndex += 1;
        currentErrorLog = errorLogs[errorLogIndex];
      }
      if (currentErrorLog && currentErrorLog.timestampFrom <= thisTs && thisTs <= currentErrorLog.timestampTo) {
        papers.push({ color: 'red', tag: currentErrorLog.message });
      } else {
        papers.push({ color: 'green', tag: new Date(thisTs).toLocaleString() });
      }
    }

    return papers.map((p) => paper(p.color, p.tag));
    // return <>sample</>;
  };

  useEffect(updateStatus, []);

  return (
    <Card withBorder radius="md">
      <Group align="left">
        <ActionIcon onClick={() => updateStatus()}>
          <IconReload />
        </ActionIcon>
      </Group>
      {loading ? (
        <Center>Loading...</Center>
      ) : (
        <Table highlightOnHover>
          <thead>
            <tr>
              <th>
                <Center>URL</Center>
              </th>
              <th>
                <Center>ENS</Center>
              </th>
              <th>
                <Center>Status</Center>
              </th>
              <th>
                <Center>Logs</Center>
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((relayer) => {
              return (
                <tr key={relayer.url}>
                  <td>
                    <a href={relayer.url} target="_blank" rel="noopener noreferrer">
                      {relayer.url}
                    </a>
                  </td>
                  <td>{relayer.ens}</td>
                  <td>
                    <Center>{relayer.status ? 'OK' : 'ERROR'}</Center>
                  </td>
                  <td style={{ width: '80%' }}>
                    <Group ref={ref} spacing="xs">
                      {makePapers(Math.floor(width / 16), relayer.errors)}
                    </Group>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
    </Card>
  );
}

import { ActionIcon, Card, Center, Checkbox, Group } from '@mantine/core';
import { IconReload } from '@tabler/icons-react';
import axios from 'axios';
import { MantineReactTable } from 'mantine-react-table';
import { useEffect, useState } from 'react';
import { Relayer, makeTable } from './makeTable';

export function RelayerListBSC() {
  const [loading, setLoading] = useState(true);
  const [onlyValid, setChecked] = useState(true);
  const [baseRelayers, setBaseData] = useState<Relayer[]>([]);
  const [data, setData] = useState<Relayer[]>([]);

  function updateStatus() {
    setLoading(true);
    axios
      .get<Relayer[]>('/relayer/bsc')
      .then((res) => {
        setBaseData(res.data.sort((a, b) => b.staked - a.staked));
        setData(res.data.sort((a, b) => b.staked - a.staked).filter((rel) => !onlyValid || rel.isValid));
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  }

  function changeValid(_onlyValid: boolean) {
    setData(baseRelayers.filter((rel) => !_onlyValid || rel.isValid));
    setChecked(_onlyValid);
  }
  const table = makeTable(data, 'BSC');

  useEffect(updateStatus, []);

  return (
    <Card withBorder radius="md">
      <Group>
        <ActionIcon onClick={() => updateStatus()}>
          <IconReload />
        </ActionIcon>
        <Checkbox
          checked={onlyValid}
          label="Show Only Valid Relayers"
          onChange={(event) => changeValid(event.currentTarget.checked)}
        />
      </Group>
      {loading ? <Center>Loading...</Center> : <MantineReactTable table={table} />}
    </Card>
  );
}

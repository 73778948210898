/* eslint-disable react/no-unstable-nested-components */
import { Button, Card, Center, Group, Select, Stack } from '@mantine/core';
import axios from 'axios';
import { MRT_ColumnDef, MantineReactTable, useMantineReactTable } from 'mantine-react-table';
import { useEffect, useMemo, useState } from 'react';

type History = {
  relayer_registered_id: number;
  ensName: string;
  relayer_registered_relayerAddress: string;
  fee: number;
  gasFee: number;
  burned: number;
  last_relayed: string;
  profit: number;
  '01': number;
  '1': number;
  '10': number;
  '100': number;
  total: number;
};

const currencyPrintOptions = {
  maximumFractionDigits: 0,
};

export function RelayProfit() {
  const [loading, setLoading] = useState(false);
  const [year, setYear] = useState<string | null>(new Date().getFullYear().toString());
  const [month, setMonth] = useState<string | null>((new Date().getMonth() + 1).toString());
  const [data, setData] = useState<History[]>([]);
  const [sum, setSum] = useState<{ sum_relay: string; sum_norelay: string; sum_bug_relay: string }>({
    sum_relay: '0',
    sum_norelay: '0',
    sum_bug_relay: '0',
  });

  function updateStatus() {
    setLoading(true);
    if (year === null || month === null) {
      setData([]);
      setLoading(false);
    }
    axios
      .get<History[]>(`/query/history/${year}/${month}`)
      .then((res) => {
        axios
          .get<{ sum_relay: string; sum_norelay: string; sum_bug_relay: string }>(`/query/sum/${year}/${month}`)
          .then((res_sum) => {
            setData(res.data.sort((a, b) => +(b.profit ?? 0) - +(a.profit ?? 0)));
            setSum(res_sum.data);
            setLoading(false);
          })
          .catch((err) => {
            console.error(err);
            setLoading(false);
          });
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  }

  const { totalSum, amount01Sum, amount1Sum, amount10Sum, amount100Sum, feeSum, gasSum, burnedSum, profitSum } =
    useMemo(
      () =>
        data.reduce(
          (acc, cur) => {
            acc.totalSum += +(cur.total ?? 0);
            acc.amount01Sum += +(cur['01'] ?? 0);
            acc.amount1Sum += +(cur['1'] ?? 0);
            acc.amount10Sum += +(cur['10'] ?? 0);
            acc.amount100Sum += +(cur['100'] ?? 0);
            acc.feeSum += +(cur.fee ?? 0);
            acc.gasSum += +(cur.gasFee ?? 0);
            acc.burnedSum += +(cur.burned ?? 0);
            acc.profitSum += +(cur.profit ?? 0);
            return acc;
          },
          {
            totalSum: 0,
            amount01Sum: 0,
            amount1Sum: 0,
            amount10Sum: 0,
            amount100Sum: 0,
            feeSum: 0,
            gasSum: 0,
            burnedSum: 0,
            profitSum: 0,
          },
        ),
      [data],
    );

  const columns = useMemo<MRT_ColumnDef<History>[]>(
    () => [
      {
        header: 'ENS Name',
        accessorKey: 'ensName',
        Footer: () => <Stack>Sum</Stack>,
      },
      // {
      //   header: 'Relayer Address',
      //   accessorKey: 'relayer_registered_relayerAddress',
      // },
      {
        header: 'Total',
        accessorKey: 'total',
        Footer: () => <Stack>{totalSum.toFixed(1)}</Stack>,
      },
      {
        header: '100',
        accessorKey: '100',
        Footer: () => <Stack>{amount100Sum}</Stack>,
      },
      {
        header: '10',
        accessorKey: '10',
        Footer: () => <Stack>{amount10Sum}</Stack>,
      },
      {
        header: '1',
        accessorKey: '1',
        Footer: () => <Stack>{amount1Sum}</Stack>,
      },
      {
        header: '0.1',
        accessorKey: '01',
        Footer: () => <Stack>{amount01Sum}</Stack>,
      },
      {
        header: 'Fee(ETH)',
        accessorFn: (row) => row.fee?.toFixed(2) ?? '-',
        Footer: () => <Stack>{feeSum.toFixed(2)}</Stack>,
      },
      {
        header: 'Gas(ETH)',
        accessorFn: (row) => row.gasFee?.toFixed(2) ?? '-',
        Footer: () => <Stack>{gasSum.toFixed(2)}</Stack>,
      },
      {
        header: 'Burned(TORN)',
        accessorFn: (row) => row.burned?.toFixed(2) ?? '** BUG **',
        Footer: () => <Stack>{burnedSum.toFixed(2)}</Stack>,
      },
      {
        header: 'Profit(KRW)',
        accessorFn: (row) => (+(row.profit ?? 0)).toLocaleString('en-US', currencyPrintOptions) ?? '-',
        Footer: () => <Stack>{profitSum.toLocaleString('en-US', currencyPrintOptions)}</Stack>,
      },
    ],
    [data],
  );

  const table = useMantineReactTable({
    columns,
    // sort by score desc
    data,
    enableFilterMatchHighlighting: false,
    manualPagination: true,
    enableBottomToolbar: false,
    initialState: {
      density: 'xs',
    },
    renderEmptyRowsFallback: () => (
      <Center style={{ height: 400 }}>
        <div>No data</div>
      </Center>
    ),
  });

  useEffect(updateStatus, []);

  const years: { value: string; label: string }[] = [{ value: 'all', label: 'All' }];
  const months: { value: string; label: string }[] = [{ value: 'all', label: 'All' }];

  for (let i = 2022; i <= new Date().getFullYear(); i += 1) {
    years.push({
      value: i.toString(),
      label: i.toString(),
    });
  }
  for (let i = 1; i <= 12; i += 1) {
    months.push({
      value: i.toString(),
      label: i.toString(),
    });
  }

  return (
    <Card withBorder radius="md">
      <Group style={{ marginBottom: 15 }}>
        <Select
          value={year}
          onChange={(value) => {
            setYear(value);
            if (value === 'all') {
              setMonth('all');
            }
          }}
          data={years}
        />
        <Select
          value={month}
          onChange={(value) => {
            if (year === 'all') {
              setMonth('all');
            } else {
              setMonth(value);
            }
          }}
          maxDropdownHeight={500}
          data={months}
        />
        <Button onClick={() => updateStatus()}>Query</Button>
      </Group>
      <div>Relay : {sum.sum_relay}</div>
      <div>NoRelay : {sum.sum_norelay}</div>
      <div>BugRelay : {sum.sum_bug_relay}</div>
      {loading ? <Center>Loading...</Center> : <MantineReactTable table={table} />}
    </Card>
  );
}

/* eslint-disable no-param-reassign */
import { Stack } from '@mantine/core';
import { MRT_ColumnDef, useMantineReactTable } from 'mantine-react-table';
import { useMemo } from 'react';

export type Relayer = {
  address: string;
  ens: string;
  staked: number;
  status: boolean;
  isValid: boolean;
  url: string;
  fee?: number;
  score?: number;
  probability?: number;
  currentQueue?: number;
  version?: string;
};

export function makeTable(data: Relayer[], network: string) {
  let scoreSum = 0;
  data.forEach((relayer) => {
    relayer.fee = relayer.fee ? parseFloat(relayer.fee.toString()) : undefined;
    if (relayer.fee === undefined || !relayer.isValid) {
      return;
    }
    if (network === 'ETH') {
      if (relayer.fee >= 0.53 || relayer.staked < 500) {
        relayer.score = 0;
      } else if (relayer.fee <= 0.33) {
        relayer.score = relayer.staked;
      } else {
        relayer.score = relayer.staked * (1 - 25 * (relayer.fee - 0.33) ** 2);
      }
    } else if (network === 'BSC') {
      if (relayer.fee >= 0.3 || relayer.staked < 500) {
        relayer.score = 0;
      } else if (relayer.fee <= 0.01) {
        relayer.score = relayer.staked;
      } else {
        relayer.score = relayer.staked * (1 - (relayer.fee - 0.01) ** 2 / 0.0841);
      }
    }
    scoreSum += relayer.score ?? 0;
  });
  data.forEach((relayer) => {
    if (relayer.score === undefined) {
      return;
    }
    relayer.probability = (relayer.score / scoreSum) * 100;
  });
  const sum: { stake: number; score: number; fee: number } = useMemo(
    () =>
      data.reduce(
        (acc, cur) => {
          acc.stake += +(cur.staked ?? 0);
          acc.score += +(cur.score ?? 0);
          acc.fee = Math.min(acc.fee, cur.fee ?? 0.53);
          return acc;
        },
        {
          stake: 0,
          score: 0,
          fee: 0.53,
        },
      ),
    [data],
  );
  const columns = useMemo<MRT_ColumnDef<Relayer>[]>(
    () => [
      {
        accessorKey: 'address',
        header: 'Address',
        // count of relayers
        Footer: () => <Stack>Count: {data.length}</Stack>,
      },
      {
        accessorKey: 'ens',
        header: 'ENS',
      },
      {
        accessorKey: 'url',
        header: 'URL',
      },
      {
        accessorKey: 'fee',
        header: 'Fee',
        Footer: () => <Stack>Min: {sum.fee}</Stack>,
      },
      {
        accessorKey: 'version',
        header: 'Version',
      },
      {
        header: 'Staked',
        // round to 2 decimal places
        accessorFn: (relayer) => relayer.staked.toFixed(2),
        Footer: () => <Stack>Sum: {sum.stake.toFixed(2)}</Stack>,
      },
      {
        header: 'Score',
        accessorFn: (relayer) => relayer.score?.toFixed(2) ?? '',
        Footer: () => <Stack>Sum: {sum.score.toFixed(2)}</Stack>,
      },
      {
        header: 'Probability',
        accessorFn: (relayer) => relayer.probability?.toFixed(2) ?? '',
      },
    ],
    [data],
  );

  return useMantineReactTable({
    columns,
    // sort by score desc
    data: data.sort((a, b) => (b.score ?? 0) - (a.score ?? 0)),
    enableFilterMatchHighlighting: false,
    manualPagination: true,
    enableBottomToolbar: false,
    initialState: {
      density: 'xs',
    },
  });
}

import { ActionIcon, Card, Center, Group, Table } from '@mantine/core';
import { IconReload, IconTrash } from '@tabler/icons-react';
import axios from 'axios';
import { useEffect, useState } from 'react';

type Log = {
  timestampFrom: number;
  timestampTo: number;
  message: string;
};

interface DataItem {
  url: string;
  ens: string;
  tag: string;
  status: boolean;
  errors: Log[];
}

export function RelayerErrors() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<DataItem[]>([]);

  function updateStatus() {
    setLoading(true);
    axios
      .get<DataItem[]>('/logs')
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  }

  function clearErrors(url: string) {
    setLoading(true);
    axios
      .get(`/logs/clear?url=${url}`)
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  }

  useEffect(updateStatus, []);

  return (
    <Card withBorder radius="md">
      <Group align="left">
        <ActionIcon onClick={() => updateStatus()}>
          <IconReload />
        </ActionIcon>
      </Group>
      {loading ? (
        <Center>Loading...</Center>
      ) : (
        <Table highlightOnHover>
          <thead>
            <tr>
              <th>
                <Center>URL</Center>
              </th>
              <th>
                <Center>ENS</Center>
              </th>
              <th> </th>
              <th>
                <Center>Errors</Center>
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((item) => {
              return (
                <tr key={item.url}>
                  <td>
                    <a href={item.url}>{item.url}</a>
                  </td>
                  <td>{item.ens}</td>
                  <td>
                    <ActionIcon onClick={() => clearErrors(item.url)}>
                      <IconTrash />
                    </ActionIcon>
                  </td>
                  <td style={{ width: '80%' }} align="left">
                    {item.errors.slice(0, 10).map((error) => {
                      // show each error list
                      return (
                        <li key={error.timestampFrom}>
                          {new Date(error.timestampFrom).toLocaleString()} ~{' '}
                          {new Date(error.timestampTo).toLocaleString()} - {error.message}
                        </li>
                      );
                    })}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
    </Card>
  );
}

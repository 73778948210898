/* eslint-disable react/no-unstable-nested-components */
import { Button, Card, Center, Group, Select } from '@mantine/core';
import axios from 'axios';
import { MRT_ColumnDef, MantineReactTable, useMantineReactTable } from 'mantine-react-table';
import { useEffect, useMemo, useState } from 'react';

type History = {
  withdraw_relayerAddress: string;
  rel_ensName: string;
  symbol: string;
  amount: number;
  feeETH: string;
  burnedTORN: number;
  profit: string;
  gasETH: string;
  recipient: string;
  tx_timestamp: number;
};

const currencyPrintOptions = {
  maximumFractionDigits: 0,
};

export function RelayHistory() {
  // const [loading, setLoading] = useState(true);
  const [amount, setAmount] = useState<string | null>('all');
  const [data, setData] = useState<History[]>([]);

  function updateStatus() {
    // setLoading(true);
    axios
      .get<History[]>(`/query/withdraw/${amount}`)
      .then((res) => {
        setData(res.data);
        // setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        // setLoading(false);
      });
  }

  const columns = useMemo<MRT_ColumnDef<History>[]>(
    () => [
      {
        header: 'Relayer Address',
        accessorKey: 'withdraw_relayerAddress',
        accessorFn: (row) => (
          <a href={`https://etherscan.io/address/${row.withdraw_relayerAddress}`} target="_blank" rel="noreferrer">
            {row.withdraw_relayerAddress}
          </a>
        ),
      },
      {
        header: 'ENS Name',
        accessorKey: 'rel_ensName',
      },
      {
        header: 'Amount',
        accessorKey: 'amount',
      },
      {
        header: 'Fee(ETH)',
        accessorKey: 'feeETH',
      },
      {
        header: 'Burned(TORN)',
        accessorKey: 'burnedTORN',
      },
      {
        header: 'Gas(ETH)',
        accessorKey: 'gasETH',
      },
      {
        header: 'Profit',
        accessorFn: (row) => (+(row.profit ?? 0)).toLocaleString('en-US', currencyPrintOptions) ?? '-',
      },
      {
        header: 'Recipient',
        accessorKey: 'recipient',
        accessorFn: (row) => (
          <a href={`https://etherscan.io/address/${row.recipient}`} target="_blank" rel="noreferrer">
            {row.recipient}
          </a>
        ),
      },
      {
        header: 'Date Time',
        accessorFn: (row) => new Date(row.tx_timestamp * 1000).toLocaleString(),
      },
    ],
    [data],
  );

  const table = useMantineReactTable({
    columns,
    // sort by score desc
    data,
    enableSorting: false,
    enableFilters: true,
    initialState: {
      density: 'xs',
      pagination: {
        pageSize: 20,
        pageIndex: 0,
      },
    },
    pageCount: 20,
    renderEmptyRowsFallback: () => (
      <Center style={{ height: 400 }}>
        <div>No data</div>
      </Center>
    ),
  });

  useEffect(updateStatus, []);

  return (
    <Card withBorder radius="md">
      <Group style={{ marginBottom: 15 }}>
        <Select
          value={amount}
          onChange={(value) => {
            setAmount(value);
          }}
          data={[
            { value: 'all', label: 'All' },
            { value: '0.1', label: '0.1' },
            { value: '1', label: '1' },
            { value: '10', label: '10' },
            { value: '100', label: '100' },
          ]}
        />
        <Button onClick={() => updateStatus()}>Query</Button>
      </Group>
      <MantineReactTable table={table} />
    </Card>
  );
}

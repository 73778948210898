/*
import { AppShell, MantineProvider } from '@mantine/core';
import React from 'react';
import './App.css';
import { Routes, Route, BrowserRouter as Router } from 'react-router-dom';
import { NavbarSimple } from './Navbar';
import { RelayerListBSC } from './pages/relayer-list/RelayerListBSC';
import { RelayerListETH } from './pages/relayer-list/RelayerListETH';
import { RelayerErrors } from './pages/relayer-status/RelayerErrors';
import { RelayerStatus } from './pages/relayer-status/RelayerStatus';
import { RelayProfit } from './pages/RelayProfit';

function App() {
  return (
    <MantineProvider theme={{ colorScheme: 'dark' }} withGlobalStyles withNormalizeCSS>
      <Router>
        <AppShell padding="md" navbar={<NavbarSimple />}>
          <Routes>
            <Route path="/" element={<RelayProfit />} />
            <Route path="/relayer-eth" element={<RelayerListETH />} />
            <Route path="/relayer-bsc" element={<RelayerListBSC />} />
            <Route path="/relayer-status" element={<RelayerStatus />} />
            <Route path="/relayer-errors" element={<RelayerErrors />} />
          </Routes>
        </AppShell>
      </Router>
    </MantineProvider>
  );
}

export default App;
*/
import { MantineProvider, ScrollArea, Tabs } from '@mantine/core';
import { useLocalStorage } from '@mantine/hooks';
import { IconCurrencyEthereum, IconCurrencyBitcoin, IconHistory, IconChartPie } from '@tabler/icons-react';
import { useRef } from 'react';
import './App.css';
import { RelayerListBSC } from './pages/relayer-list/RelayerListBSC';
import { RelayerListETH } from './pages/relayer-list/RelayerListETH';
import { RelayerErrors } from './pages/relayer-status/RelayerErrors';
import { RelayerStatus } from './pages/relayer-status/RelayerStatus';
import { RelayHistory } from './pages/RelayHistory';
import { RelayProfit } from './pages/RelayProfit';

function App() {
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const [value, setValue] = useLocalStorage({
    key: '_lastTab',
    defaultValue: 'profit',
  });
  const [width] = windowSize.current;
  return (
    <MantineProvider theme={{ colorScheme: 'dark' }} withGlobalStyles withNormalizeCSS>
      <Tabs defaultValue={value} style={{ paddingTop: '1rem' }}>
        <ScrollArea w={width} offsetScrollbars>
          <Tabs.List style={{ width: 'max-content' }}>
            <Tabs.Tab value="ETH" icon={<IconCurrencyEthereum size="1rem" />} onClick={() => setValue('ETH')}>
              Relayer List ETH
            </Tabs.Tab>
            <Tabs.Tab value="BSC" icon={<IconCurrencyBitcoin size="1rem" />} onClick={() => setValue('BSC')}>
              Relayer List BSC
            </Tabs.Tab>
            {/* <Tabs.Tab
              value="relayerStatus"
              icon={<IconAntennaBars5 size="1rem" />}
              onClick={() => setValue('relayerStatus')}
            >
              Status
            </Tabs.Tab>
            <Tabs.Tab
              value="relayerError"
              icon={<IconAntennaBarsOff size="1rem" />}
              onClick={() => setValue('relayerError')}
            >
              Error Logs
            </Tabs.Tab> */}
            <Tabs.Tab value="profit" icon={<IconChartPie size="1rem" />} onClick={() => setValue('profit')}>
              Relay Profit
            </Tabs.Tab>
            <Tabs.Tab value="history" icon={<IconHistory size="1rem" />} onClick={() => setValue('history')}>
              Relay History
            </Tabs.Tab>
          </Tabs.List>
        </ScrollArea>
        <ScrollArea w={width} offsetScrollbars style={{ padding: 0, margin: 0 }}>
          <div style={{ width: width < 1920 ? 1920 : width - 20 }}>
            <Tabs.Panel value="ETH" pt="xs">
              <RelayerListETH />
            </Tabs.Panel>
            <Tabs.Panel value="BSC" pt="xs">
              <RelayerListBSC />
            </Tabs.Panel>
            <Tabs.Panel value="relayerStatus" pt="xs">
              <RelayerStatus />
            </Tabs.Panel>
            <Tabs.Panel value="relayerError" pt="xs">
              <RelayerErrors />
            </Tabs.Panel>
            <Tabs.Panel value="profit" pt="xs">
              <RelayProfit />
            </Tabs.Panel>
            <Tabs.Panel value="history" pt="xs">
              <RelayHistory />
            </Tabs.Panel>
          </div>
        </ScrollArea>
      </Tabs>
    </MantineProvider>
  );
}

export default App;
